/**
 * @author Alexander Wåland
 */

import PropTypes from 'prop-types';
import './../stylesheets/button.less';
import './../stylesheets/button-states.less';

/**
 * Buttons are available in different sizes and colors depending on what they are used for.
 */
const Button = ({
  block,
  children,
  circle,
  Component,
  className,
  doubleLines,
  elemRef,
  focus,
  ghost,
  iconPosition,
  inverted,
  rounded,
  size,
  thin,
  transparent,
  ...props
}) => {
  const classNames = ['btn', `btn-${size}`].concat(className ? className.split(' ') : []);

  block && classNames.push('btn-block');
  if (transparent) {
    inverted && classNames.push('btn-transparent-inverted focus-inverted');
    !inverted && classNames.push('btn-transparent-default');
    thin && classNames.push('btn-thin');
  } else {
    inverted && classNames.push('btn-inverted');
    focus && classNames.push('btn-green');
    !focus && classNames.push('btn-default');
  }

  if (ghost) {
    classNames.push('btn-clean');
  }

  if (circle) {
    classNames.push('btn-circle');
  }

  if (iconPosition) {
    classNames.push('btn-icon');
    classNames.push(`btn-icon-${iconPosition}`);
  }

  if (doubleLines) {
    classNames.push('btn-double-lines');
  }

  if (rounded) {
    classNames.push('btn-rounded');
  }

  // Router link
  if (children.type && children.type.displayName === 'Link') {
    return {
      ...children,
      props: {
        ...children.props,
        className: classNames.join(' '),
        children:
          <span className="btn-link-text">{children.props.children}</span>

      }
    };
  }

  if ((!Component || Component === 'button') && props.href) {
    Component = 'a';
    props.type = null;
  }

  if (Component === 'button' && !props.type) {
    props.type = 'button';
  }

  return (
    <Component
      className={classNames.join(' ')}
      {...(elemRef ? { ref: elemRef } : {})}
      {...props}
    >
      <span className="btn-link-text">{children}</span>
    </Component>
  );
};

Button.propTypes = {
  /**
   * Button is the default node type.
   */
  Component: PropTypes.node,
  /**
   * Set true to get a full width button. Perfect for buttons placed in Grid.
   */
  block: PropTypes.bool,
  /**
   * Content.
   */
  children: PropTypes.node.isRequired,
  /**
   * Set to true for a circular button.
   */
  circle: PropTypes.bool,
  /**
   * Custom classes
   */
  className: PropTypes.string,
  /**
   * Set true to get a disabled button
   */
  disabled: PropTypes.bool,
  /**
   * Usen in tight areas where the content in a button needs to be placed on two lines.
   */
  doubleLines: PropTypes.bool,
  elemRef: PropTypes.object,
  /**
   * Set true for a CTA button.
   */
  focus: PropTypes.bool,
  /**
   * Set true for a ghost button
   */
  ghost: PropTypes.bool,
  /**
   * Add href to turn button in to a <a-tag.
   */
  href: PropTypes.string,
  /**
   * Position of icon.
   */
  iconPosition: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Set true for a primary button.
   * Set false for a primary button on dark surfaces.
   */
  inverted: PropTypes.bool,
  /**
   * Run callback function on click.
   */
  onClick: PropTypes.func,
  /**
   * Set true for a rounded button
   */
  rounded: PropTypes.bool,
  /**
   * 300 is default.
   * 100 small button
   */
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([300, 100, 500, 700])
  ]),
  /**
   * Use thin borders. May only be used by Svenska Spel Sport & Casino
   */
  thin: PropTypes.bool,
  /**
   * Set true for a secondary button
   * Combine with inverted true on dark surfaces for secondary button.
   */
  transparent: PropTypes.bool,
  /**
   * Use submit for forms.
   */
  type: PropTypes.oneOf(['button', 'submit'])
};

Button.defaultProps = {
  block: false,
  circle: false,
  className: '',
  Component: 'button',
  elemRef: null,
  focus: false,
  ghost: false,
  inverted: false,
  size: 300,
  thin: false,
  transparent: false,
  type: 'button'
};

export default Button;
